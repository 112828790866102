import {SAVE_TERMS, TOGGLE_IS_ERROR, TOGGLE_IS_LOADING} from './actions';

const initialStateTerms = {
    isLoading: false,
    isError: false,
    terms: {},
    createdAt: null
};

// Persists terms across pages
export const termsReducer = (state = initialStateTerms, action) => {
    const {type, payload} = action;
    switch (type) {
        case SAVE_TERMS: {
            return {
                ...state,
                terms: payload,
                createdAt: Date.now()
            };
        }
        case TOGGLE_IS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }
        case TOGGLE_IS_ERROR: {
            return {
                ...state,
                isError: payload
            };
        }
        default:
            return state;
    }
};
