export const SAVE_TERMS = 'SAVE_TERMS';
export const TOGGLE_IS_LOADING = 'TOGGLE_IS_LOADING';
export const TOGGLE_IS_ERROR = 'TOGGLE_IS_ERROR';

export const saveTerms = payload => ({
    type: SAVE_TERMS,
    payload
});

export const toggleIsLoading = payload => ({
    type: TOGGLE_IS_LOADING,
    payload
});

export const toggleIsError = payload => ({
    type: TOGGLE_IS_ERROR,
    payload
});
