import React, {Component} from 'react';
import {connect} from 'react-redux';
import Body from './Body';
import {toggleCCT} from './actions';
import {saveUser} from '../../../AWS/actions';
import InvalidRoute from '../../Layout/Router/InvalidRoute';
import {getData, postData} from '../../../DataAccessLayer';
import {
    lti,
    ltiCourse,
    myCoursesTeaching
} from '../../../DataAccessLayer/services';
import PortalPageLoader from '../../common/PortalPageLoader';
import axios from 'axios';
import PortalToast from '../../common/PortalToast';
import {saveMyCoursesTaking, saveMyCoursesTeaching} from '../actions';

//This class is specifically for CCT on canvas
class CCTWrapper extends Component {
    state = {isLoading: true, isAuthorized: false};

    componentDidMount() {
        this.initAuth().then(() => {
            this.loadCourse();
        });
    }

    initAuth = async () => {
        // Get the full path from the URL
        const path = window.location.pathname;
        // Split the path into segments
        const segments = path.split('/');
        // Get the base64 string
        const parameter = segments[segments.length - 1];

        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_API_GATEWAY_BASE_URL}${lti}/validate/${parameter}`
            );

            if (data.accessToken) {
                this.props.saveUser(data);
                this.setState({isAuthorized: true});
            }
        } catch (err) {
            console.log(err);
        }
    };

    loadCourse = async () => {
        try {
            const course = await getData(ltiCourse, true);
            const {teaching} = await this.loadCourses([
                {code: course.SSBSECT_TERM_CODE}
            ]);
            const cctCourse = teaching.find(entry =>
                entry?.CRNS?.includes(course.CRNS)
            );

            this.props.toggleCCT({
                ...course,
                SCHEDULE: cctCourse?.SCHEDULE ?? [
                    {
                        MEETING_DAYS: course.MEETING_DAYS,
                        BEGIN_TIME: course.BEGIN_TIME,
                        END_TIME: course.END_TIME,
                        TIMES: course.TIMES
                    }
                ]
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({isLoading: false});
        }
    };

    //Θ(1) Loads courses from backend
    loadCourses = terms => {
        return new Promise((resolve, reject) => {
            postData(
                myCoursesTeaching,
                //We will always a single termcode over here. Adding map for future proofing
                terms?.map(term => term.code)
            )
                .then(courses => {
                    this.props.saveMyCoursesTaking(courses.taking);
                    this.props.saveMyCoursesTeaching(courses.teaching);
                    resolve(courses);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    render() {
        const {isLoading, isAuthorized} = this.state;

        // If there's an error in fetching the terms API response, throw error manully so that the fallback error component with 'Oops! Something went wrong.' message is displayed.
        if (!this.props.isTermsLoading && this.props.isTermsError) {
            throw new Error('Loading terms failed');
        }

        return isLoading || this.props.isTermsLoading ? (
            <PortalPageLoader />
        ) : isAuthorized ? (
            !this.props.isTermsLoading &&
            !this.props.isTermsError && (
                <div>
                    <PortalToast />
                    <Body isCctBald={true} />
                </div>
            )
        ) : (
            <InvalidRoute />
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course,
        user: state.AWSReducer.user,
        isTermsLoading: state.termsReducer.isLoading,
        isTermsError: state.termsReducer.isError
    };
};

const mapDispatchToProps = dispatch => ({
    toggleCCT: course => dispatch(toggleCCT(course)),
    saveUser: user => dispatch(saveUser(user)),
    saveMyCoursesTaking: coursesTaking =>
        dispatch(saveMyCoursesTaking(coursesTaking)),
    saveMyCoursesTeaching: coursesTeaching =>
        dispatch(saveMyCoursesTeaching(coursesTeaching))
});

export default connect(mapStateToProps, mapDispatchToProps)(CCTWrapper);
