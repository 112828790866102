import {getData} from '../../../DataAccessLayer';
import {terms} from '../../../DataAccessLayer/services';
import {Time} from '../../common/MomentTime';

// Θ(1) Loads the previous, current and next term details
// TODO: Revisit when the util function can access the store directly
export const loadTerms = ({
    termsCreatedAt,
    termsData,
    toggleIsLoading,
    toggleIsError,
    saveTerms
}) => {
    if (
        !termsCreatedAt ||
        Time.University(termsCreatedAt).format('YYYY-MM-DD') !==
            Time.University().format('YYYY-MM-DD') ||
        !termsData['CURRENT_TERM']
    ) {
        toggleIsLoading(true);
        toggleIsError(false);
        getData(terms, true)
            .then(result => saveTerms(result))
            .catch(error => {
                console.log('Error loading terms', error);
                toggleIsError(true);
            })
            .finally(() => toggleIsLoading(false));
    }
};
